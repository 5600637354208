<template>
  <nav class="navbar bg-white border-bottom">
    <div class="container py-1">
      <div class="navbar-brand">
        <div class="d-flex align-items-center text-black">
          <img src="@/assets/logo.svg" width="40" alt="logo" />
          <h1 class="mb-0 h5 ml-3 fw-bold">IT Inventory</h1>
        </div>
      </div>

      <div class="dropdown my-auto">
        <a
          class="flex text-decoration-none"
          href="#"
          role="button"
          data-bs-auto-close="outside"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div class="d-flex align-items-center">
            <img
              :src="avatar(user.name)"
              width="40"
              height="40"
              alt="pp"
              class="rounded-circle mr-3"
            />
            <div class="d-none d-sm-block">
              <p class="mb-0 text-black font-weight-medium">{{ user.name }}</p>
              <p class="mb-0 text-muted small">
                <i class="fa-solid fa-circle text-success small"></i>
                Active
              </p>
            </div>
          </div>
        </a>

        <ul class="dropdown-menu dropdown-menu-end pl-0">
          <li>
            <p class="dropdown-item text-black small disabled mb-0" href="#">
              <i class="fa-solid fa-circle text-success small mr-1"></i>
              Status Active
            </p>
          </li>
          <li><hr class="dropdown-divider" /></li>
          <li>
            <router-link
              to="/change-password"
              class="dropdown-item text-black small mb-2"
            >
              <i class="fa-solid fa-lock mr-1"></i>
              Ganti Password
            </router-link>
          </li>
          <li>
            <a
              href="#"
              class="dropdown-item text-danger small"
              @click="handleLogout()"
            >
              <i class="fa-solid fa-arrow-right-from-bracket mr-1"></i>
              Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "AppTopbarHeader",
  data() {
    return {
      avatar: (name) =>
        `https://ui-avatars.com/api/?name=${name}?background=0D6EFD`,
    };
  },
  computed: {
    user() {
      return this.$store.state.app.user;
    },
  },
  methods: {
    handleLogout() {
      localStorage.clear();
      this.$store.commit("SET_USER_APP", "");
      this.$store.commit("SET_TOKEN_APP", "");
      console.log("logout");
      this.$router.push("/login");
    },
    handleCopy(code) {
      // remove space in code
      code = code.replace(/\s/g, "");
      navigator.clipboard.writeText(code);
      alert("Kode berhasil disalin");
    },
  },
};
</script>
